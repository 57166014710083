const earlyPaymentReductionFactors = {
    65: {
        54: 0.588,
        55: 0.613,
        56: 0.64,
        57: 0.67,
        58: 0.701,
        59: 0.735,
        60: 0.771,
        61: 0.81,
        62: 0.852,
        63: 0.898,
        64: 0.948,
        65: 1
    },
    66: {
        54: 0.556,
        55: 0.58,
        56: 0.606,
        57: 0.633,
        58: 0.663,
        59: 0.694,
        60: 0.729,
        61: 0.765,
        62: 0.805,
        63: 0.848,
        64: 0.895,
        65: 0.947,
        66: 1
    },
    67: {
        54: 0.525,
        55: 0.548,
        56: 0.572,
        57: 0.598,
        58: 0.626,
        59: 0.655,
        60: 0.687,
        61: 0.722,
        62: 0.76,
        63: 0.8,
        64: 0.844,
        65: 0.892,
        66: 0.945,
        67: 1
    },
    68: {
        54: 0.495,
        55: 0.516,
        56: 0.539,
        57: 0.563,
        58: 0.589,
        59: 0.617,
        60: 0.648,
        61: 0.68,
        62: 0.715,
        63: 0.753,
        64: 0.795,
        65: 0.84,
        66: 0.889,
        67: 0.943,
        68: 1
    }
};

export default earlyPaymentReductionFactors;
