const addedPensionByPeriodicalContributionFactors = {
    65: {
        16: [2.49, 2.49, 2.67],
        17: [2.59, 2.59, 2.79],
        18: [2.7, 2.7, 2.92],
        19: [2.82, 2.82, 3.04],
        20: [2.94, 2.94, 3.16],
        21: [3.05, 3.05, 3.3],
        22: [3.18, 3.18, 3.43],
        23: [3.32, 3.32, 3.57],
        24: [3.45, 3.45, 3.73],
        25: [3.6, 3.6, 3.88],
        26: [3.75, 3.75, 4.04],
        27: [3.91, 3.91, 4.21],
        28: [4.06, 4.06, 4.38],
        29: [4.24, 4.24, 4.57],
        30: [4.41, 4.41, 4.76],
        31: [4.6, 4.6, 4.96],
        32: [4.79, 4.79, 5.16],
        33: [4.99, 4.99, 5.37],
        34: [5.19, 5.19, 5.6],
        35: [5.41, 5.41, 5.83],
        36: [5.63, 5.63, 6.06],
        37: [5.87, 5.87, 6.32],
        38: [6.1, 6.1, 6.57],
        39: [6.36, 6.36, 6.84],
        40: [6.61, 6.61, 7.13],
        41: [6.89, 6.89, 7.41],
        42: [7.18, 7.18, 7.72],
        43: [7.46, 7.46, 8.03],
        44: [7.77, 7.77, 8.35],
        45: [8.09, 8.09, 8.7],
        46: [8.43, 8.43, 9.05],
        47: [8.76, 8.76, 9.42],
        48: [9.12, 9.12, 9.8],
        49: [9.5, 9.5, 10.2],
        50: [9.89, 9.89, 10.62],
        51: [10.3, 10.3, 11.05],
        52: [10.72, 10.72, 11.49],
        53: [11.16, 11.16, 11.96],
        54: [11.62, 11.62, 12.44],
        55: [12.09, 12.09, 12.95],
        56: [12.59, 12.59, 13.47],
        57: [13.12, 13.12, 14.03],
        58: [13.67, 13.67, 14.6],
        59: [14.24, 14.24, 15.2],
        60: [14.84, 14.84, 15.84],
        61: [15.48, 15.48, 16.5],
        62: [16.16, 16.16, 17.2],
        63: [16.86, 16.86, 17.94],
        64: [17.62, 17.62, 18.72],
        65: [17.73, 17.73, 18.86],
        66: [17.18, 17.18, 18.31],
        67: [16.63, 16.63, 17.75],
        68: [16.06, 16.06, 17.19],
        69: [15.49, 15.49, 16.62],
        70: [14.93, 14.93, 16.04],
        71: [14.35, 14.35, 15.47],
        72: [13.78, 13.78, 14.9],
        73: [13.21, 13.21, 14.31],
        74: [12.63, 12.63, 13.74],
        75: [12.36, 12.36, 13.44]
    },
    66: {
        16: [2.33, 2.33, 2.52],
        17: [2.44, 2.44, 2.63],
        18: [2.53, 2.53, 2.74],
        19: [2.64, 2.64, 2.86],
        20: [2.74, 2.74, 2.98],
        21: [2.87, 2.87, 3.1],
        22: [2.98, 2.98, 3.22],
        23: [3.1, 3.1, 3.36],
        24: [3.24, 3.24, 3.5],
        25: [3.37, 3.37, 3.64],
        26: [3.51, 3.51, 3.8],
        27: [3.65, 3.65, 3.96],
        28: [3.81, 3.81, 4.13],
        29: [3.97, 3.97, 4.29],
        30: [4.14, 4.14, 4.47],
        31: [4.31, 4.31, 4.66],
        32: [4.48, 4.48, 4.85],
        33: [4.67, 4.67, 5.05],
        34: [4.86, 4.86, 5.25],
        35: [5.06, 5.06, 5.47],
        36: [5.27, 5.27, 5.69],
        37: [5.49, 5.49, 5.93],
        38: [5.71, 5.71, 6.17],
        39: [5.95, 5.95, 6.42],
        40: [6.18, 6.18, 6.69],
        41: [6.44, 6.44, 6.95],
        42: [6.71, 6.71, 7.24],
        43: [6.97, 6.97, 7.53],
        44: [7.26, 7.26, 7.83],
        45: [7.56, 7.56, 8.15],
        46: [7.86, 7.86, 8.49],
        47: [8.19, 8.19, 8.82],
        48: [8.52, 8.52, 9.18],
        49: [8.87, 8.87, 9.55],
        50: [9.22, 9.22, 9.94],
        51: [9.6, 9.6, 10.34],
        52: [9.99, 9.99, 10.75],
        53: [10.4, 10.4, 11.18],
        54: [10.82, 10.82, 11.63],
        55: [11.26, 11.26, 12.1],
        56: [11.72, 11.72, 12.59],
        57: [12.21, 12.21, 13.1],
        58: [12.72, 12.72, 13.63],
        59: [13.25, 13.25, 14.19],
        60: [13.8, 13.8, 14.77],
        61: [14.38, 14.38, 15.38],
        62: [15.0, 15.0, 16.02],
        63: [15.65, 15.65, 16.71],
        64: [16.34, 16.34, 17.42],
        65: [17.08, 17.08, 18.18],
        66: [17.18, 17.18, 18.31],
        67: [16.63, 16.63, 17.75],
        68: [16.06, 16.06, 17.19],
        69: [15.49, 15.49, 16.62],
        70: [14.93, 14.93, 16.04],
        71: [14.35, 14.35, 15.47],
        72: [13.78, 13.78, 14.9],
        73: [13.21, 13.21, 14.31],
        74: [12.63, 12.63, 13.74],
        75: [12.36, 12.36, 13.44]
    },
    67: {
        16: [2.19, 2.19, 2.36],
        17: [2.28, 2.28, 2.48],
        18: [2.38, 2.38, 2.58],
        19: [2.47, 2.47, 2.68],
        20: [2.57, 2.57, 2.79],
        21: [2.68, 2.68, 2.92],
        22: [2.79, 2.79, 3.03],
        23: [2.91, 2.91, 3.16],
        24: [3.03, 3.03, 3.3],
        25: [3.15, 3.15, 3.43],
        26: [3.29, 3.29, 3.57],
        27: [3.42, 3.42, 3.72],
        28: [3.56, 3.56, 3.87],
        29: [3.72, 3.72, 4.03],
        30: [3.87, 3.87, 4.2],
        31: [4.02, 4.02, 4.37],
        32: [4.2, 4.2, 4.56],
        33: [4.37, 4.37, 4.74],
        34: [4.55, 4.55, 4.93],
        35: [4.73, 4.73, 5.14],
        36: [4.92, 4.92, 5.34],
        37: [5.13, 5.13, 5.56],
        38: [5.33, 5.33, 5.79],
        39: [5.56, 5.56, 6.02],
        40: [5.78, 5.78, 6.27],
        41: [6.02, 6.02, 6.52],
        42: [6.26, 6.26, 6.79],
        43: [6.51, 6.51, 7.06],
        44: [6.78, 6.78, 7.34],
        45: [7.05, 7.05, 7.64],
        46: [7.34, 7.34, 7.94],
        47: [7.64, 7.64, 8.26],
        48: [7.94, 7.94, 8.6],
        49: [8.26, 8.26, 8.94],
        50: [8.6, 8.6, 9.3],
        51: [8.95, 8.95, 9.67],
        52: [9.31, 9.31, 10.05],
        53: [9.68, 9.68, 10.45],
        54: [10.07, 10.07, 10.87],
        55: [10.48, 10.48, 11.3],
        56: [10.91, 10.91, 11.75],
        57: [11.35, 11.35, 12.22],
        58: [11.81, 11.81, 12.72],
        59: [12.31, 12.31, 13.23],
        60: [12.82, 12.82, 13.77],
        61: [13.35, 13.35, 14.33],
        62: [13.91, 13.91, 14.92],
        63: [14.51, 14.51, 15.54],
        64: [15.14, 15.14, 16.21],
        65: [15.82, 15.82, 16.9],
        66: [16.53, 16.53, 17.64],
        67: [16.63, 16.63, 17.75],
        68: [16.06, 16.06, 17.19],
        69: [15.49, 15.49, 16.62],
        70: [14.93, 14.93, 16.04],
        71: [14.35, 14.35, 15.47],
        72: [13.78, 13.78, 14.9],
        73: [13.21, 13.21, 14.31],
        74: [12.63, 12.63, 13.74],
        75: [12.36, 12.36, 13.44]
    },
    68: {
        16: [2.05, 2.05, 2.23],
        17: [2.13, 2.13, 2.32],
        18: [2.22, 2.22, 2.43],
        19: [2.31, 2.31, 2.53],
        20: [2.41, 2.41, 2.63],
        21: [2.51, 2.51, 2.74],
        22: [2.61, 2.61, 2.86],
        23: [2.72, 2.72, 2.97],
        24: [2.84, 2.84, 3.09],
        25: [2.95, 2.95, 3.22],
        26: [3.07, 3.07, 3.36],
        27: [3.2, 3.2, 3.49],
        28: [3.34, 3.34, 3.63],
        29: [3.47, 3.47, 3.79],
        30: [3.61, 3.61, 3.94],
        31: [3.77, 3.77, 4.11],
        32: [3.92, 3.92, 4.28],
        33: [4.08, 4.08, 4.45],
        34: [4.25, 4.25, 4.64],
        35: [4.42, 4.42, 4.82],
        36: [4.61, 4.61, 5.02],
        37: [4.79, 4.79, 5.22],
        38: [4.99, 4.99, 5.44],
        39: [5.19, 5.19, 5.65],
        40: [5.4, 5.4, 5.88],
        41: [5.62, 5.62, 6.11],
        42: [5.85, 5.85, 6.36],
        43: [6.08, 6.08, 6.61],
        44: [6.33, 6.33, 6.88],
        45: [6.58, 6.58, 7.16],
        46: [6.85, 6.85, 7.44],
        47: [7.13, 7.13, 7.74],
        48: [7.41, 7.41, 8.05],
        49: [7.71, 7.71, 8.36],
        50: [8.02, 8.02, 8.7],
        51: [8.33, 8.33, 9.04],
        52: [8.67, 8.67, 9.4],
        53: [9.01, 9.01, 9.78],
        54: [9.38, 9.38, 10.16],
        55: [9.75, 9.75, 10.56],
        56: [10.15, 10.15, 10.97],
        57: [10.56, 10.56, 11.4],
        58: [10.97, 10.97, 11.87],
        59: [11.43, 11.43, 12.34],
        60: [11.89, 11.89, 12.83],
        61: [12.38, 12.38, 13.34],
        62: [12.9, 12.9, 13.88],
        63: [13.44, 13.44, 14.46],
        64: [14.02, 14.02, 15.06],
        65: [14.63, 14.63, 15.69],
        66: [15.28, 15.28, 16.37],
        67: [15.98, 15.98, 17.1],
        68: [16.06, 16.06, 17.19],
        69: [15.49, 15.49, 16.62],
        70: [14.93, 14.93, 16.04],
        71: [14.35, 14.35, 15.47],
        72: [13.78, 13.78, 14.9],
        73: [13.21, 13.21, 14.31],
        74: [12.63, 12.63, 13.74],
        75: [12.36, 12.36, 13.44]
    }
};

export default addedPensionByPeriodicalContributionFactors;
